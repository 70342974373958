<template>
  <section class="head">
    <div class="bg" :style="{ backgroundImage: 'url(' + topImg + ')' }"></div>
    <div class="content" v-if="Object.keys(dataInfo).length > 0">
      <div class="cover">
        <img :src="dataInfo.coverImg" alt="" />
      </div>
      <div class="info">
        <div class="title">
          <div class="name">{{ dataInfo.courseName }}</div>
          <div v-if="dataInfo.openLeave == 1">
            <a-button
              type="primary"
              danger
              class="leave red"
              v-if="dataInfo.isLeave <= 0"
            >
              <template #icon><i class="icon-qj"></i></template>
              {{ $t("train.ask_for_leave") }}
              <!-- 请假 -->
            </a-button>
            <a-button
              type="primary"
              danger
              class="leave hui"
              disabled
              v-else-if="dataInfo.isLeave == 2"
            >
              {{ $t("train.approving_for_leave") }}
              <!-- 请假审批中 -->
            </a-button>
            <a-button
              type="primary"
              danger
              class="leave white"
              v-else-if="dataInfo.isLeave == 1"
            >
              <template #icon><i class="icon-qj"></i></template>
              {{ $t("train.cancel_leave") }}
              <!-- 取消请假 -->
            </a-button>
          </div>
        </div>
        <div class="wrap date">
          {{ moment(dataInfo.trainTime[0]).format("YYYY-MM-DD HH:mm") }} ~
          {{ moment(dataInfo.trainTime[1]).format("YYYY-MM-DD HH:mm") }}
        </div>
        <div class="wrap">
          {{ $t("train.class_place") }}：{{ dataInfo.address }}
        </div>
        <div class="wrap" v-if="dataInfo.locationAddress">
          {{ $t("AttendanceAddress") }}：{{ dataInfo.locationAddress }}
        </div>
        <div class="wrap">
          <span
            class="span"
            v-if="dataInfo.trainLecturers && dataInfo.trainLecturers.length"
          >
            <!-- 讲师： -->
            {{ $t("XB_Home_Instructor") }}：<template
              v-for="(item, index) in dataInfo.trainLecturers"
              :key="index"
            >
              <span>{{ item.lecturerName }}</span>{{ index + 1 == dataInfo.trainLecturers.length ? "" : "、" }}
            </template>
          </span>
          <span class="span" v-else>
            <!-- 讲师： -->
            {{ $t("XB_Home_Instructor") }}：<span>{{ dataInfo.lecturerName }}</span>
          </span>
          <span class="span">
            {{ $t("train.lesson_hours") }}：{{ dataInfo.courseTimes }}
          </span>
        </div>
        <div class="wrap complete">
          {{ $t('CM_CCondition') }}：
          <div class="condition">
            <div class="checkbox" v-if="1 & dataInfo.completeCondition">
              <a-checkbox :checked="dataInfo.inTime > 0">
                {{ $t("Lab_LessonDetail_T_SignIn") }}
              </a-checkbox>
            </div>
            <div class="checkbox" v-if="2 & dataInfo.completeCondition">
              <a-checkbox :checked="dataInfo.outTime > 0">
                {{ $t("Lab_LessonDetail_T_SignOut") }}
              </a-checkbox>
            </div>
            <div class="checkbox" v-if="4 & dataInfo.completeCondition">
              <a-checkbox :checked="dataInfo.secTime > 0">
                {{ $t("CM_evaluation_course") }}
              </a-checkbox>
            </div>
            <div class="checkbox" v-if="8 & dataInfo.completeCondition">
              <a-checkbox :checked="dataInfo.selTime > 0">
                {{ $t("CM_evaluation_lecturer") }}
              </a-checkbox>
            </div>
            <div class="checkbox" v-if="16 & dataInfo.completeCondition">
              <a-checkbox :checked="dataInfo.leaderTime > 0 && dataInfo.studentTime > 0">
                {{ $t("CM_leaders_evaluate_students") }}
              </a-checkbox>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="mian" v-if="Object.keys(dataInfo).length > 0">
    <div class="detail">
      <a-spin :spinning="loadding">
        <a-tabs>
          <!-- 课程详情 -->
          <a-tab-pane key="1" :tab="$t('train.course_detail')">
            <div class="content" style="min-height: 500px">
              <div class="report" v-if="dataInfo.examUid > 0">
                <p>
                  <em>{{ $t("train.exam_results") }}：</em>
                  <!-- 考试成绩： -->
                  <span class="score">{{ dataInfo.examScores }}</span>
                  <span class="fen">{{ $t("CM_Points") }}</span>
                </p>
                <p v-if="dataInfo.examRemark != ''">
                  <span :class="['remark', { omit: !readMore }]">
                    <em>{{ $t("train.teacher_comment") }}：</em
                    >{{ dataInfo.examRemark }}
                    <!-- 老师评语： -->
                  </span>
                  <span class="more" @click="readMore = !readMore">{{
                    !readMore ? $t("train.view_full") : $t("CM_Retract")
                  }}</span>
                  <!-- "查看完整" : "收起" -->
                </p>
              </div>
              <div v-if="dataInfo.intro" v-html="dataInfo.intro"></div>
              <template
                v-if="
                  dataInfo.attachmentList && dataInfo.attachmentList.length > 0
                "
              >
                <p class="mark">
                  <em class="line"></em> {{ $t("CM_LB_AttachmentFile") }}
                </p>
                <!-- 附件 -->
                <div class="info-file">
                  <ul
                    v-for="item in dataInfo.attachmentList"
                    :key="item.documentId"
                  >
                    <li @click="preview(item)">
                      <PaperClipOutlined style="font-size: 18px" />
                      <span class="title">
                        <a-tooltip>
                          <template #title>
                            <template v-if="item.secretData">
                              {{ htsySecret[item.secretData] }}
                            </template>
                            {{ item.fileName }}
                          </template>
                          <template v-if="item.secretData">
                            {{ htsySecret[item.secretData] }}
                          </template>
                          {{ item.fileName }}
                        </a-tooltip>
                      </span>
                      （{{ getFileSize(item.fileSize) }}）
                    </li>
                  </ul>
                </div>
              </template>
              <a-empty
                v-if="!dataInfo.examUid && !dataInfo.intro && !dataInfo.attachmentList"
                :image="require('@/assets/image/no_data_3.png')"
                :image-style="{ height: '186px' }"
                style="padding: 60px 0"
              >
                <template #description>
                  <span style="color: #999">{{ $t("Pub_Lab_NoData") }}</span>
                </template>
              </a-empty>
            </div>
          </a-tab-pane>
          <!-- 活动附件 -->
          <a-tab-pane key="2" :tab="$t('train.activity_attachments')">
            <div class="activity content">
              <template
                v-if="
                  dataInfo.openUpload > 0 &&
                  dataInfo.trainAttachmentList &&
                  dataInfo.trainAttachmentList.length > 0
                "
              >
                <template v-if="files.image.length">
                  <p class="mark">
                    <em class="line"></em> {{ $t("train.activity_photos") }}
                  </p>
                  <!-- 活动图片 -->
                  <ul class="v-list">
                    <template
                      v-for="item in files.image"
                      :key="item.documentId"
                    >
                      <li>
                        <a-image class="img" :src="item.filePath" />
                      </li>
                    </template>
                  </ul>
                </template>
                <template v-if="files.other.length">
                  <p class="mark">
                    <em class="line"></em>
                    {{ $t("train.activity_attachments") }}
                  </p>
                  <!-- 活动附件 -->
                  <div class="other-file">
                    <ul>
                      <li v-for="item in files.other" :key="item.documentId">
                        <a-tooltip placement="bottom">
                          <template #title>
                            <template v-if="item.secretData">
                              {{ htsySecret[item.secretData] }}
                            </template>
                            {{ item.fileName + "." + item.suffix }}
                          </template>
                          <a href="#javascript;;" @click="viewOrDownFile(item)">
                            <img
                              v-if="item.fileType == 1"
                              src="../../assets/image/train/icon_shipin.png"
                            />
                            <img
                              v-if="item.fileType == 2"
                              src="../../assets/image/train/icon_yinpin.png"
                            />
                            <img
                              v-if="item.fileType == 3"
                              src="../../assets/image/train/icon_wendang.png"
                            />
                            <img
                              v-if="item.fileType == 6"
                              src="../../assets/image/train/icon_h5.png"
                            />
                            <span class="title">
                              <template v-if="item.secretData">
                                {{ htsySecret[item.secretData] }}
                              </template>
                              {{ item.fileName }}
                            </span>
                          </a>
                        </a-tooltip>
                      </li>
                    </ul>
                  </div>
                </template>
              </template>
              <a-empty
                v-else
                :description="$t('no_attachment')"
                style="padding: 100px 0"
              />
              <!-- 暂无附件 -->
            </div>
          </a-tab-pane>
          <!-- 课程讨论 -->
          <a-tab-pane
            v-if="hasComment"
            key="3"
            :tab="$t('train.course_comment')"
            forceRender
          >
            <div class="content">
              <my-comment
                v-if="commentParams.resourceId > 0"
                :CommentParams="commentParams"
              ></my-comment>
            </div>
          </a-tab-pane>
        </a-tabs>
      </a-spin>
    </div>
    <div class="cards">
      <div class="wrap">
        <div class="wrap-title">{{ $t("train.attendance") }}</div>
        <div class="wrap-main">
          <div class="row">
            <div class="col">
              <div class="label">{{ $t("train.sign_in_time") }}：</div>
            </div>
            <div class="col">
              <div class="label">
                {{ dateFormat(dataInfo.inStartTime) }} -
                {{ dateFormat(dataInfo.inEndTime) }}
              </div>
              <div class="value">
                <span class="green" v-if="dataInfo.inTime > 0">
                  {{ $t("Pub_Btn_AlreadySign") }}
                </span>
                <!-- 已签到 -->
                <span class="orange" v-else>{{ $t("train.no_sign") }}</span>
                <!-- 未签到 -->
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <div class="label">{{ $t("train.sign_out_time") }}：</div>
            </div>
            <div class="col">
              <div class="label">
                {{ dateFormat(dataInfo.outStartTime) }} -
                {{ dateFormat(dataInfo.outEndTime) }}
              </div>
              <div class="value">
                <span class="green" v-if="dataInfo.outTime > 0">{{
                  $t("train.signed_out")
                }}</span>
                <!-- 已签退 -->
                <span class="orange" v-else>{{ $t("train.no_sign_out") }}</span>
                <!-- 未签退 -->
              </div>
            </div>
          </div>
          <div class="row" v-if="isShowMeet && dataInfo.trainType > 1">
            <div class="col">
              <div class="block">
                <a-button
                  type="primary"
                  block
                  v-if="platformConfig.platform == 26"
                  @click="goWxMeet(dataInfo.meetId)"
                  >{{ $t("train.enter_meeting") }}</a-button
                >
                <!-- 进入会议 -->
                <a-button
                  type="primary"
                  block
                  v-if="
                    platformConfig.platform == 31 || platformConfig.platform == 30
                  "
                  @click="goDingMeet(dataInfo.meetData)"
                  >{{ $t("train.enter_meeting") }}</a-button
                >
                <!-- 进入会议 -->
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="wrap" v-show="dataInfo.secId || dataInfo.selId || dataInfo.studentEvaluate || dataInfo.lesId">
        <div class="wrap-title">{{ $t("train.evaluation") }}</div>
        <div class="wrap-main">
          <div class="row" v-if="dataInfo.secId">
            <div class="col">
              <div class="label">{{ $t('CM_evaluation_course') }}</div>
              <div class="value green">
                <template v-if="dataInfo.secTime > 0">
                  {{ dataInfo.avgSecScore.toFixed(2)
                  }}<span>{{ $t("CM_Points") }}</span>
                </template>
                <span class="orange" v-else>{{ $t("train.not_rated") }}</span>
                <!-- 未评分 -->
              </div>
            </div>
            <div class="col">
              <div class="block">
                <a-button
                  type="primary"
                  ghost
                  block
                  v-if="dataInfo.secTime <= 0"
                  @click="openQuestionnaire(dataInfo.secId, 3)"
                  >{{ $t("ToEvaluate") }}</a-button
                >
                <a-button
                  type="primary"
                  ghost
                  block
                  v-else
                  @click="openQuestionnaire(dataInfo.secId, 3, 100)"
                  >{{ $t("train.view_reviews") }}</a-button
                >
                <!-- 查看评价 -->
              </div>
            </div>
          </div>
          <div class="row" v-if="dataInfo.selId">
            <div class="col">
              <div class="label">{{ $t('CM_evaluation_lecturer') }}</div>
              <div class="value green">
                <template v-if="dataInfo.selTime > 0">
                  {{ dataInfo.avgSelScore.toFixed(2)
                  }}<span>{{ $t("CM_Points") }}</span>
                </template>
                <span class="orange" v-else>{{ $t("train.not_rated") }}</span>
                <!-- 未评分 -->
              </div>
            </div>
            <div class="col">
              <div class="block">
                <a-button
                  type="primary"
                  ghost
                  block
                  v-if="dataInfo.selTime <= 0"
                  @click="openQuestionnaire(dataInfo.selId, 2)"
                  >{{ $t("ToEvaluate") }}</a-button
                >
                <a-button
                  type="primary"
                  ghost
                  block
                  v-else
                  @click="openQuestionnaire(dataInfo.selId, 2, 100)"
                  >{{ $t("train.view_reviews") }}</a-button
                >
                <!-- 查看评价 -->
              </div>
            </div>
          </div>
          <div class="row" v-if="dataInfo.studentEvaluate">
            <div class="col">
              <div class="label">{{ $t('CM_leaders_evaluate_students') }} -- {{ $t('SelfEvaluate') }}</div>
              <div class="value green">
                <template v-if="dataInfo.studentTime > 0">
                  {{ dataInfo.avgStudentScore.toFixed(2)
                  }}<span>{{ $t("CM_Points") }}</span>
                </template>
                <span class="orange" v-else>{{ $t("train.not_rated") }}</span>
                <!-- 未评分 -->
              </div>
            </div>
            <div class="col">
              <div class="block">
                <a-button
                  type="primary"
                  ghost
                  block
                  v-if="dataInfo.studentTime <= 0"
                  @click="openQuestionnaire(dataInfo.studentEvaluate, 4)"
                  >{{ $t("ToEvaluate") }}</a-button
                >
                <a-button
                  type="primary"
                  ghost
                  block
                  v-else
                  @click="openQuestionnaire(dataInfo.studentEvaluate, 4, 100)"
                  >{{ $t("train.view_reviews") }}</a-button
                >
                <!-- 查看评价 -->
              </div>
            </div>
          </div>
          <div class="row" v-if="dataInfo.lesId">
            <div class="col">
              <div class="label">{{ $t('CM_evaluate_students') }}</div>
              <div class="value green">
                <template v-if="dataInfo.lesTime > 0">
                  {{ dataInfo.avgLesScore.toFixed(2)
                  }}<span>{{ $t("CM_Points") }}</span>
                </template>
                <span class="orange" v-else>{{ $t("train.not_rated") }}</span>
                <!-- 未评分 -->
              </div>
            </div>
            <div class="col">
              <div class="block">
                <a-tooltip class="w-full" :title="dataInfo.lesTime <= 0 ? $t('InstructorIsNotRatedYetAndCannotViewRatings') : ''">
                  <a-button
                    type="primary"
                    ghost
                    block
                    :disabled="dataInfo.lesTime <= 0"
                    @click="openQuestionnaire(dataInfo.lesId, 1, 100)"
                    >{{ $t("train.view_reviews") }}</a-button
                  >
                </a-tooltip>
                <!-- 查看评价 -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <a-modal
    v-model:visible="videoVisible"
    :title="videoTiele"
    width="1000px"
    @ok="handleCancelVideo"
    @cancel="handleCancelVideo"
    destroyOnClose
  >
    <video
      :src="videoSrc"
      autoplay
      controls
      style="width: 100%; height: 60vh"
    ></video>
  </a-modal>
  <!-- 评价 -->
  <a-modal
    v-model:visible="questionnaireVisible"
    :title="$t('train.evaluation')"
    width="1050px"
    :footer="null"
    @ok="questionnaireSubmit()"
    :maskClosable="false"
    :keyboard="false"
    destroyOnClose
    :bodyStyle="{ overflow: 'auto', height: '70vh', padding: '0' }"
  >
    <questionnaire
      ref="questionnaireRef"
      :taskId="taskId"
      :did="did"
      :rid="rid"
      :evalType="evalType"
      :progress="progress"
      source="train"
      @on-close="getDetailData"
    />
  </a-modal>
  <docPreview
    :isVisible="previewVisible"
    :documentId="fileDocumentId"
    :fileStatus="fileStatus"
    :fileType="fileType"
    :filePath="filePath"
    :fileName="fileName"
    :fileHome="fileHome"
    @previewEnd="
      (e) => {
        previewVisible = e;
      }
    "
  />
</template>

<script>
import { useI18n } from "vue-i18n";
import {
  ref,
  getCurrentInstance,
  reactive,
  toRefs,
  watch,
  computed,
} from "vue";
import { useRouter, useRoute } from "vue-router";
import { useStore } from "vuex";
import { dateFormat, getFileSize, viewOrDownFile } from "@/utils/tools";
import { imgRegex } from "@/utils/formRules";
import { trainDetail } from "@/api/train";
import myComment from "@/components/my-comment";
import moment from "moment";
import questionnaire from "@/views/questionnaire/detail2";
import { htsySecret } from "@/utils/business";
import docPreview from "@/components/preview/index.vue";
import wxwork from "@/utils/wxwork";
export default {
  components: {
    "my-comment": myComment,
    questionnaire,
    docPreview,
  },
  setup() {
    const { t: $t } = useI18n();
    const { proxy } = getCurrentInstance();
    const route = useRoute();
    const router = useRouter();
    const store = useStore();
    const taskId = Number(route.query.id);
    const did = Number(route.query.did);

    const state = reactive({
      files: {
        image: [],
        other: [],
      },
      rateValue: 0,
      readMore: false,
      topImg: require("@/assets/image/train/top-bg.png"),
      videoVisible: false,
      videoTiele: "",
      videoSrc: "",
      questionnaireVisible: false, //问卷
      rid: 0, //评估表id
      evalType: 1, //评估类型 0任务使用 2学员评价老师 3学员评价课程
      progress: 0,
      userId: 0,
      hasComment: false,
      previewVisible: false,
      fileStatus: 1,
      fileDocumentId: 0,
      fileType: "",
      filePath: "",
      fileName: "",
      fileHome: "",
      isShowMeet: true,
    });

    const platformConfig = computed(() => store.getters.platformConfig);
    watch(
      () => store.getters.companyInfo,
      (company) => {
        state.hasComment = company.useComment == 1;
      },
      { immediate: true }
    );

    let loadding = ref(true),
      dataInfo = ref({}),
      commentParams = ref({
        page: 1,
        pageSize: 10,
        resourceId: did || 0,
        type: 4, //0知识 1课程 2任务 3新闻 4面授
        Total: 0,
      });
    
    const getDetailData = () => {
      state.questionnaireVisible = false;
      trainDetail(taskId, did).then((res) => {
        loadding.value = false;
        if (res.ret === 0) {
          // 讲师
          if (res.data.trainLecturers && res.data.trainLecturers.length) {
            res.data.lecturerUid = res.data.trainLecturers[0].lecturerUid;
            res.data.LecturerName = res.data.trainLecturers[0].lecturerName;
            res.data.lecturerType = res.data.trainLecturers[0].lecturerType;
            res.data.lecturerAvatar = res.data.trainLecturers[0].lecturerAvatar;
          }
          let now = new Date().getTime();
          let endTime = new Date(
            res.data.trainTime[1].replace(/-/g, "/")
          ).getTime();
          state.isShowMeet = now < endTime;
  
          dataInfo.value = res.data;
          // 活动附件各类型总数
          dataInfo.value.trainAttachmentType = [0, 0];
          if (
            dataInfo.value.trainAttachmentList &&
            dataInfo.value.trainAttachmentList.length > 0
          ) {
            allRender(res.data.trainAttachmentList);
          }
  
          if (wxwork.isWxWork) {
            wxwork.agentConfig();
          }
        } else {
          router.replace({
            path: `/error`,
            query: {
              title: res.msg,
              path: "/",
              name: $t("CM_Index"),
            },
          });
        }
      });
    };
    getDetailData();


    const allRender = (list) => {
      list.map((item) => {
        if (imgRegex.test(item.filePath)) {
          state.files.image.push(item);
        } else {
          state.files.other.push(item);
        }
      });
    };

    const handleCancelVideo = () => {
      state.videoVisible = false;
    };

    const questionnaireRef = ref(null);
    const questionnaireSubmit = () => {
      if (state.progress == 100) {
        state.questionnaireVisible = false;
        return false;
      }
      questionnaireRef.value.submit();
    };

    const getCourseTime = (minutes) => {
      let courseTimesH = parseInt(minutes / 60);
      let courseTimesM = minutes - parseInt(minutes / 60) * 60;
      if (courseTimesH > 0) {
        if (courseTimesM > 0) {
          return courseTimesH + $t("Pub_Hour") + courseTimesM + $t("CM_Minute");
        }
        return courseTimesH + $t("Pub_Hour");
      }
      return courseTimesM + $t("CM_Minute");
    };

    const openQuestionnaire = (rid, type, progress = 0) => {
      let tip = ''
      if (new Date() < new Date(dataInfo.value.trainTime[0])) {
        tip = $t("FaceToFaceTeachingCannotBeEvaluated");
      }
      // if (new Date() > new Date(dataInfo.value.trainTime[1])) {
      //   tip = $t('FaceToFaceInstructionHasBeenCompletedAndCannotBeAssessed');
      // }
      if (
        type == 4 &&
        dataInfo.value.trainTimeSet &&
        (Date.parse(new Date()) / 1000) < dataInfo.value.trainTimeSet
      ) {
        tip = $t("NotYetDueForAssessment");
      }
      if (tip && !progress) return proxy.$message.error(tip);
      state.rid = rid;
      state.evalType = type;
      state.progress = progress;
      state.questionnaireVisible = true;
    };

    const preview = (data) => {
      state.previewVisible = true;
      state.fileStatus = data.status;
      state.fileDocumentId = data.documentId || 0;
      state.fileType = data.fileType;
      state.filePath = data.filePath;
      state.fileName = data.fileName || "";
      state.fileHome = data.home || "";
    };

    const goWxMeet = (meetId) => {
      if (wxwork.isWxWork) {
        wxwork.startMeeting(meetId);
      } else {
        proxy.$message.error(
          $t("train.please_use_enterprise_wechat_to_participate_in_meeting")
        );
        // 请用企业微信参与会议
      }
    };

    const goDingMeet = (meetData) => {
      proxy.$message.info($t("train.is_open_dingding_meeting"));
      // 正在打开钉钉会议...
      window.open(meetData.url);
    };

    return {
      ...toRefs(state),
      dateFormat,
      getFileSize,
      viewOrDownFile,
      loadding,
      dataInfo,
      commentParams,
      moment,
      getCourseTime,
      handleCancelVideo,
      openQuestionnaire,
      questionnaireSubmit,
      questionnaireRef,
      preview,
      htsySecret,
      goWxMeet,
      goDingMeet,
      platformConfig,
      getDetailData,
    };
  },
};
</script>

<style>
.w-full {
  width: 100%;
}
</style>
<style lang="less" scoped>
.head {
  padding: 42px 0 100px;
  position: relative;
  .bg {
    .absolute-full();
    background-size: cover;
    background-position: center;
    z-index: 0;
    overflow: hidden;
    min-height: 392px;
  }
  .content {
    .mixinWrap();
    .mixinFlex(space-between);
    .cover {
      z-index: 4;
      .mixinImgWrap(450px; 250px);
      border-radius: 6px;
    }
    .info {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      margin-left: 50px;
      color: #fff;
      position: relative;
      .title {
        display: flex;
        overflow: hidden;
        .name {
          flex: 1;
          font-size: 28px;
          font-weight: bold;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        .leave {
          .icon-qj {
            display: inline-block;
            background-image: url(../../assets/image/icon-qj.png);
            width: 27px;
            height: 27px;
            vertical-align: -6px;
            margin-right: 8px;
          }
          &.red {
            background: #e63450;
            border-color: #e63450;
            letter-spacing: 2px;
          }
          &.hui {
            background: #f5f5f5;
            border-color: #d9d9d9;
          }
          &.white {
            background: #bec8c8;
            border-color: #bec8c8;
          }
          width: 160px;
          height: 44px;
          line-height: 32px;
          border-radius: 4px;
          font-size: 20px;
          position: absolute;
          right: 0;
          top: 0;
        }
      }
      .date {
        font-size: 20px;
        font-weight: 600;
      }
      .wrap {
        .span {
          padding-right: 10px;
        }
      }
      .complete {
        display: flex;
        .checkbox {
          display: inline-block;
          margin-right: 10px;
          margin-bottom: 10px;
          padding: 0 4px;
          border-radius: 30px;
          border: 1px solid #fff;
          pointer-events: none;
          ::v-deep(.ant-checkbox-wrapper) {
            color: #fff;
            font-size: 14px;
            .ant-checkbox-inner {
              border-radius: 50%;
            }
            .ant-checkbox-checked .ant-checkbox-inner {
              background-color: #44B44F;
              border-color: #44B44F;
            }
          }
        }
      }
      p {
        margin-bottom: 12px;
        font-size: 20px;
        em {
          margin-left: 20px;
          vertical-align: -1px;
        }
        &.duration {
          color: #f5f5f5;
          font-size: 16px;
          .span:not(last-child) {
            margin-right: 20px;
          }
        }
      }
      .box {
        font-size: 18px;
        margin-top: 20px;
        height: 80px;
        .time {
          font-size: 24px;
        }
        .address {
          font-size: 16px;
          color: #f5f5f5;
        }
      }
    }
  }
}

.mian {
  position: relative;
  z-index: 1;
  padding-bottom: 64px;
  .mixinWrap();
  .mixinFlex(space-between);
  .container {
    flex: 1;
  }
  .detail {
    flex: 1;
    margin-right: 40px;
    background-color: #fff;
    border-radius: 6px;
    margin-top: -56px;
    ::v-deep(.ant-tabs-nav-wrap) {
      padding: 0 30px;
    }
    ::v-deep(.ant-tabs-nav .ant-tabs-tab) {
      padding: 16px 0px;
      font-size: 16px;
      color: #999;
    }
    ::v-deep(.ant-tabs-nav .ant-tabs-tab-active) {
      color: @color-theme;
    }
    ::v-deep(.ant-tabs-ink-bar) {
      background-color: @color-theme;
    }
    .content {
      padding: 14px 30px 10px 30px;
      .mark {
        font-size: 15px;
        font-weight: bold;
        margin: 30px 0;
        .line {
          width: 5px;
          height: 16px;
          display: inline-block;
          background: @color-theme;
          vertical-align: -2px;
        }
      }
      .info-file {
        ul {
          li {
            padding: 8px 15px;
            background: #f1f1f1;
            margin-bottom: 15px;
            color: #8b8b8b;
            width: 50%;
            border-radius: 6px;
            display: flex;
            justify-content: left;
            align-items: center;
            cursor: pointer;
            &:hover {
              color: @color-theme;
            }
            .title {
              margin-left: 10px;
              .mixinEllipsis(15px);
            }
          }
        }
      }
      .other-file {
        ul {
          li {
            width: 72px;
            margin-bottom: 15px;
            margin-right: 30px;
            border-radius: 6px;
            display: inline-flex;
            flex-direction: column;
            text-align: center;
            cursor: pointer;
            font-size: 10px;
            img {
              margin-bottom: 12px;
            }
            &:hover {
              color: @color-theme;
            }
            .title {
              .mixinEllipsis(34px,2);
            }
          }
        }
      }
    }
    .report {
      width: 100%;
      background: #f5f5f6;
      margin-bottom: 30px;
      padding: 20px;
      p:nth-child(1) {
        margin: 0;
      }
      p:nth-child(2) {
        margin: 20px 0 0;
      }
      em {
        margin-right: 16px;
        font-size: 15px;
        font-weight: 600;
      }
      .score {
        color: @color-theme;
        font-weight: 500;
        font-size: 17px;
      }
      .fen {
        color: @color-theme;
        font-size: 15px;
      }
      .remark {
        display: inline;
        &.omit {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          width: 89%;
          float: left;
        }
      }
      .more {
        margin-left: 25px;
        color: @color-theme;
        cursor: pointer;
      }
    }
    .activity {
      padding-top: 0px;
      margin-top: -10px;
      min-height: 500px;
      .v-list {
        display: inline-block;
        margin-bottom: -20px;
        li {
          position: relative;
          float: left;
          margin-right: 20px;
          margin-bottom: 20px;
          cursor: pointer;
          &:hover {
            .title {
              color: @color-theme;
            }
          }
          &:nth-child(3n) {
            margin-right: 0;
          }
          // ::v-deep(.img){
          //   width: 262px;
          //   height: 172px;
          //   margin-bottom: 5px;
          // }
          ::v-deep(.ant-image) {
            width: 262px;
            height: 172px;
            margin-bottom: 5px;
            overflow: hidden;
            display: flex;
            align-items: center;
            img {
              width: 100%;
              cursor: pointer;
            }
          }
          video {
            width: 262px;
            height: 148px;
          }
          .mask {
            position: absolute;
            width: 262px;
            height: 148px;
            background: #0000005c;
            line-height: 196px;
            text-align: center;
            .icon-play {
              display: inline-block;
              width: 60px;
              height: 60px;
              background-size: contain;
              background-image: url(../../assets/image/play.png);
            }
          }
        }
      }
    }
  }
  .cards {
    width: 400px;
    min-height: 400px;
    background: url('~@/assets/image/train/card-bg.png') no-repeat top right;
    background-size: 160px 140px;
    margin-top: -56px;
    padding: 20px;
    background-color: #fff;
    border-radius: 6px;
    .wrap {
      margin: 20px 0;
      &-title {
        font-size: 20px;
        color: #333;
        font-weight: 600;
      }
      .col {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 12px;
        .green {
          color: #1ab700;
        }
        .orange {
          color: orange;
        }
        .block {
          flex: 1;
        }
      }
    }
  }
}
</style>
